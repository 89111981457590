<template>
    <img
        v-if="showing == false"
        class="icon-show-title"
        @click="turnOffOn(true)"
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABXElEQVR4nN2Vu0oDQRSGvyJewA2IYlAI5Al8A8EIYq1vIeQJtNHSZ7AQUlmbWIuYysLG9VKpkJDSQoIKgq6s/MgwzNlNhEX0wLAwc875Zs9t4D/KVJHOd4FbYKGom8dAom9F+2XgEHgAnoFXoAccAw1gZhRIxYCsA2/a99cA2AJKw0IWgfcRIQlwDlTznM8DN55h7EAmFMo60ApA7oCa5Tw1vDZuFzsQV7YDuukFoxBgP6B8auTElXbArukrLRk3X5XTbgZkxbBNw/gtJ4bSrM4fM8JVNmw7LqBjKEU672fkZNKorjMXUM/5zb2cxG94kA9g2c9DM+CkpbNxQfoKVzcHchAohq9w+D2QqBR9SZ1eGc14kTUwa2oWH9JWtURK/JpKOBSuMXKkqrZPRlxWnwSlpAE2GMLxE3D/EwgaxQ2N5p5G9YuSfARsAtOBKVzIozUHXAI7/NXnll+RT3SJxryK+e+1AAAAAElFTkSuQmCC">

    <img
        v-if="showing == true"
        class="icon-show-title"
        @click="turnOffOn(false)"
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABH0lEQVR4nO2UvU5CQRCFv4poxELFzkIT8TVAan9ewehDGJFefBsE30ILLbil2lzt4QEwk5xCNzOXxcJQ8CWT3MycM7uZ3buwYtnZBq6AIVAAU0Wh3KU0C7MO9NRsNidMcytPFnvAc0bjWRKvwMG85kfAl2MeAC1gQ9HWiFKdeZtR813gwzFdV2yo6+jfgYYnfgx2btSAe+ATKIG+csbI8T2QcB7MtaV636lZzjgOvKc/FygCUV310qlZztgMvON/XeAsELUrRnSnWifwnqTn4F27oWo1LVI6h1x1OX7R0BVLxV1ieo7+DdiJDM3gRxvpttQVnWDn5j0k46l4+sNT8QLsk8maRjPNaDwBbuRZmC3gQoc2VrOJvgeqmWbFEvMNriXJVcp1zQIAAAAASUVORK5CYII=">

</template>

<script type="text/javascript">

import $ from 'jquery';

    export default {
        name: 'show-title',
        props: {
            className: String,
            startShowing: Boolean
        },
        data(){
            return {
                showing: true
            }
        },
        methods: {
            /**
            * Start visible with showing true
            * Add an print disabled class and remove this class
            * when should show it again
            */
            turnOffOn(value){
                this.showing = value;
                this.showing ? $(`.${this.className}`).removeClass('not-visible')
                : $(`.${this.className}`).addClass('not-visible');
            }
        },
        mounted() {
            // Melhor passar estartShowing sempre que iniciar o componentesCompartilhados
            // caso contrario nao sera exibido
            this.turnOffOn(this.startShowing != null ? this.startShowing : true);
        }
    }
</script>

<style scoped >
.icon-show-title {
    float: right;
    margin-left: 5px;
}

@media print {
    .icon-show-title
    {
        display: none;
    }
}
</style>

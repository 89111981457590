<template>
    <div>
Not found
    </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>

</style>